<template>
  <div class="roleInfo">
    <a-page-header title="企业账号管理" @back="() => $router.go(-1)" />
    <div class="actionBar">
      <a-space>
        <a-tree-select
          allowClear
          v-model="listParams.status"
          style="width: 140px"
          :tree-data="check_status"
          placeholder="状态(全部)"
          @change="statuChange"/>
        <a-input-search
          
          v-model="listParams.keyword"
          placeholder="请输入关键词搜索"
          enter-button="查询"
          style="width: 350px"
          allowClear
          @search="onSearch"
      /></a-space>

      <a-button icon="plus" type="primary" @click="showModal">
        添加账号</a-button
      >
    </div>
    <a-modal
      :title="modalTitle"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      okText="确定"
      cancelText="取消"
    >
      <a-form
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 16 }"
        :from="addFromData"
      >
        <a-form-item label="用户名">
          <a-input v-model="addFromData.username" />
        </a-form-item>
        <a-form-item label="昵称">
          <a-input v-model="addFromData.nickname" />
        </a-form-item>
        <a-form-item label="密码">
          <a-input
            v-model="addFromData.password"
            :placeholder="
              modalTitle == '新增账户'
                ? '不填写密码默认为66666666'
                : '不填写密码表示不修改原密码'
            "
          />
        </a-form-item>
        <a-form-item label="状态">
          <a-radio-group v-model="addFromData.status">
            <a-radio :value="1">
              启用
            </a-radio>
            <a-radio :value="0">
              禁用
            </a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-table
      :columns="columns"
      :data-source="data"
      rowKey="id"
      :pagination="{
        total: count,
        current: listParams.page,
        pageSize: listParams.limit,
        showTotal: (total) => `共 ${total} 条`,
      }"
      @change="pageChange"
    >
      <span slot="status" slot-scope="text, record">
        <a-tag
          :color="text == 1 ? 'green' : '#999'"
          :title="text == 1 ? '点击禁用' : '点击启用'"
          @click="statusChange(record)"
        >
          {{ text == 1 ? "启用" : "禁用" }}
        </a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <FontIcon
          @click.native="editMenu(record)"
          title="修改"
          type="icon-xiugai"
          size="22"
        ></FontIcon>
      </span>
    </a-table>
  </div>
</template>

<script>
import { memberIndex, memberDataSave, memberSetStatus } from "@/apis/index";
const columns = [
  {
    title: "角色ID",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
    align: "center",
    width: 120,
  },
  {
    title: "用户名",
    dataIndex: "username",
    scopedSlots: { customRender: "username" },
  },
  {
    title: "用户昵称",
    dataIndex: "nickname",
    scopedSlots: { customRender: "nickname" },
  },
  {
    title: "设置状态",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];
const check_status = [
  {
    title: "启用",
    value: 1,
  },
  {
    title: "禁用",
    value: 2,
  },
];

export default {
  data() {
    return {
      check_status,
      columns,
      data: [],
      visible: false,
      confirmLoading: false,
      modalTitle: "",
      addFromData: {},
      addMenu: [],
      listParams: {
        page: 1,
        limit: 10,
        keyword: "",
        company_id: this.$route.query.id,
        status: undefined,
      },
      count: 0,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getMemberIndex();
  },
  methods: {
    statuChange() {
      this.listParams.page = 1;
      this.getMemberIndex();
    },

    statusChange(rec) {
      memberSetStatus({ id: rec.id, status: rec.status == 1 ? 0 : 1 }).then(
        (res) => {
          if (res.code == 1) {
            this.getMemberIndex();
            this.$message.success("状态修改成功");
          }
        }
      );
    },

    showModal() {
      this.modalTitle = "新增账户";
      (this.addFromData = {
        id: "",
        username: "",
        nickname: "",
        password: "",
        status: 1,
        company_id: this.$route.query.id,
      }),
        (this.visible = true);
    },
    handleOk() {
      this.confirmLoading = true;
      memberDataSave(this.addFromData).then((res) => {
        if (res.code == 1) {
          this.getMemberIndex();
          this.visible = false;
        }
        this.confirmLoading = false;
      });
    },
    handleCancel() {
      this.visible = false;
    },
    editMenu(record) {
      this.modalTitle = "修改角色信息";
      this.visible = true;
      this.addFromData = {
        id: record.id,
        username: record.username,
        nickname: record.nickname,
        password: "",
        status: record.status,
        company_id: this.$route.query.id,
      };
    },
    pageChange(a) {
      this.listParams.page = a.current;
      this.getMemberIndex();
    },
    getMemberIndex() {
      memberIndex(this.listParams).then((res) => {
      

        if (res.data.list.length == 0 && this.listParams.page > 1) {
          this.listParams.page--;
          this.getMemberIndex();
        } else {
          this.data = res.data.list;
          this.count = res.data.count;
        }
      });
    },
    onSearch() {
      this.listParams.page = 1;
      this.getMemberIndex();
    },
  },
};
</script>

<style lang="less" scoped>
.roleInfo {
  .actionBar {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }
}
</style>
