var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"roleInfo"},[_c('a-page-header',{attrs:{"title":"企业账号管理"},on:{"back":function () { return _vm.$router.go(-1); }}}),_c('div',{staticClass:"actionBar"},[_c('a-space',[_c('a-tree-select',{staticStyle:{"width":"140px"},attrs:{"allowClear":"","tree-data":_vm.check_status,"placeholder":"状态(全部)"},on:{"change":_vm.statuChange},model:{value:(_vm.listParams.status),callback:function ($$v) {_vm.$set(_vm.listParams, "status", $$v)},expression:"listParams.status"}}),_c('a-input-search',{staticStyle:{"width":"350px"},attrs:{"placeholder":"请输入关键词搜索","enter-button":"查询","allowClear":""},on:{"search":_vm.onSearch},model:{value:(_vm.listParams.keyword),callback:function ($$v) {_vm.$set(_vm.listParams, "keyword", $$v)},expression:"listParams.keyword"}})],1),_c('a-button',{attrs:{"icon":"plus","type":"primary"},on:{"click":_vm.showModal}},[_vm._v(" 添加账号")])],1),_c('a-modal',{attrs:{"title":_vm.modalTitle,"visible":_vm.visible,"confirm-loading":_vm.confirmLoading,"okText":"确定","cancelText":"取消"},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"label-col":{ span: 5 },"wrapper-col":{ span: 16 },"from":_vm.addFromData}},[_c('a-form-item',{attrs:{"label":"用户名"}},[_c('a-input',{model:{value:(_vm.addFromData.username),callback:function ($$v) {_vm.$set(_vm.addFromData, "username", $$v)},expression:"addFromData.username"}})],1),_c('a-form-item',{attrs:{"label":"昵称"}},[_c('a-input',{model:{value:(_vm.addFromData.nickname),callback:function ($$v) {_vm.$set(_vm.addFromData, "nickname", $$v)},expression:"addFromData.nickname"}})],1),_c('a-form-item',{attrs:{"label":"密码"}},[_c('a-input',{attrs:{"placeholder":_vm.modalTitle == '新增账户'
              ? '不填写密码默认为66666666'
              : '不填写密码表示不修改原密码'},model:{value:(_vm.addFromData.password),callback:function ($$v) {_vm.$set(_vm.addFromData, "password", $$v)},expression:"addFromData.password"}})],1),_c('a-form-item',{attrs:{"label":"状态"}},[_c('a-radio-group',{model:{value:(_vm.addFromData.status),callback:function ($$v) {_vm.$set(_vm.addFromData, "status", $$v)},expression:"addFromData.status"}},[_c('a-radio',{attrs:{"value":1}},[_vm._v(" 启用 ")]),_c('a-radio',{attrs:{"value":0}},[_vm._v(" 禁用 ")])],1)],1)],1)],1),_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.data,"rowKey":"id","pagination":{
      total: _vm.count,
      current: _vm.listParams.page,
      pageSize: _vm.listParams.limit,
      showTotal: function (total) { return ("共 " + total + " 条"); },
    }},on:{"change":_vm.pageChange},scopedSlots:_vm._u([{key:"status",fn:function(text, record){return _c('span',{},[_c('a-tag',{attrs:{"color":text == 1 ? 'green' : '#999',"title":text == 1 ? '点击禁用' : '点击启用'},on:{"click":function($event){return _vm.statusChange(record)}}},[_vm._v(" "+_vm._s(text == 1 ? "启用" : "禁用")+" ")])],1)}},{key:"action",fn:function(text, record){return _c('span',{},[_c('FontIcon',{attrs:{"title":"修改","type":"icon-xiugai","size":"22"},nativeOn:{"click":function($event){return _vm.editMenu(record)}}})],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }